import React from 'react';
import { Table, Drawer, Button, Spin, Form, Input, Row, Col, Card } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';

interface IRegistration {
	created_at: string;
	guardian_name: string;
	guardian_phone: string;
	wards: {
		name: string;
		age: string;
		gender: string;
		has_passport: boolean;
	}[];
	escorting_guardians: {
		name: string;
		age: string;
		gender: string;
		has_passport: boolean;
	}[];
}

const App = () => {
	const APIPrefix =
			process.env.NODE_ENV == 'development'
				? 'http://127.0.0.1:8000'
				: 'https://api.a-mole.net',
		[loading, setLoading] = React.useState<boolean>(false),
		[selectedRegistration, setSelectedRegistration] =
			React.useState<IRegistration>(),
		[registrations, setRegistrations] = React.useState<IRegistration[]>([]),
		columns = [
			{
				title: 'Guardian Name',
				dataIndex: 'guardian_name',
				key: 'guardian_name',
			},
			{
				title: 'Guardian Phone',
				dataIndex: 'guardian_phone',
				key: 'guardian_phone',
			},
			{
				title: 'Number of wards',
				render: (r: IRegistration) => r.wards.length,
			},
			{
				title: 'Date Registered',
				render: (r: IRegistration) => r.created_at,
			},
			{
				title: 'Actions',
				render: (r: IRegistration) => (
					<Button type="primary" onClick={() => setSelectedRegistration(r)}>
						<EyeOutlined />
					</Button>
				),
			},
		],
		wardColumns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Has passport',
				dataIndex: 'has_passport',
				key: 'has_passport',
			},
			{
				title: 'School',
				dataIndex: 'school',
				key: 'school',
			},
			{
				title: 'Grade',
				dataIndex: 'grade',
				key: 'grade',
			},
			{
				title: 'Age',
				dataIndex: 'age',
				key: 'age',
			},
			{
				title: 'Gender',
				dataIndex: 'gender',
				key: 'gender',
			},
		],
		escortColumns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Has passport',
				dataIndex: 'has_passport',
				key: 'has_passport',
			},
			{
				title: 'Phone',
				dataIndex: 'phone',
				key: 'phone',
			},
			{
				title: 'Gender',
				dataIndex: 'gender',
				key: 'gender',
			},
		],
		[authenticated, setAuthenticated] = React.useState(false),
		submitHandler = (payload: Record<string, any>) => {
			if (payload.passphrase == 'bennycrypto99') {
				setAuthenticated(true);
			}
		};

	React.useEffect(() => {
		if (authenticated) {
			setLoading(true);
			axios
				.get<IRegistration[]>(`${APIPrefix}/api/smartdubai/registrations`)
				.then(res => {
					setRegistrations(res.data);
					setLoading(false);
				});
		}
	}, [authenticated]);

	return authenticated ? (
		loading ? (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Spin />
			</div>
		) : (
			<>
				<Drawer
					drawerStyle={{ width: 'min(600px, 90vw)' }}
					bodyStyle={{ width: 'min(600px, 90vw)' }}
					width="min(600px, 90vw)"
					closable
					maskClosable
					visible={!isEmpty(selectedRegistration)}
					onClose={() => setSelectedRegistration(undefined)}
				>
					<h5>Wards</h5>
					<Table
						pagination={false}
						dataSource={selectedRegistration?.wards}
						columns={wardColumns}
					/>
					<h5>Escorting Guardians</h5>
					<Table
						pagination={false}
						dataSource={selectedRegistration?.escorting_guardians}
						columns={escortColumns}
					/>
				</Drawer>
				<Table
					pagination={false}
					dataSource={registrations}
					columns={columns}
				/>
			</>
		)
	) : (
		<div
			style={{
				background: 'orange',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100vw',
				height: '100vh',
			}}
		>
			<Card style={{ width: 'min(400px, 80vw)' }}>
				<Form layout="vertical" onFinish={submitHandler}>
					<Row gutter={[12, 12]}>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 24 }}
							md={{ span: 24 }}
							lg={{ span: 24 }}
						>
							<Form.Item label="Enter Passphrase" name="passphrase">
								<Input.Password />
							</Form.Item>
						</Col>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 24 }}
							md={{ span: 24 }}
							lg={{ span: 24 }}
						>
							<Form.Item>
								<Button
									block
									htmlType="submit"
									type="primary"
									style={{ background: 'orange', borderColor: 'orange' }}
								>
									Submit
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Card>
		</div>
	);
};

export default App;
