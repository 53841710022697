import React from 'react';
import bgImg from './assets/img/bg-img.jpg';
import logo from './assets/img/logo.png';
import ad1 from './assets/img/ad1.jpg';
import ad2 from './assets/img/ad2.jpg';
import sideImage from './assets/img/side-image.jpg';
import './assets/css/app.css';
import 'antd/dist/antd.css';
import {
	Button,
	Typography,
	Form,
	Input,
	Row,
	Col,
	Card,
	InputNumber,
	Divider,
	Tooltip,
	Radio,
} from 'antd';
import axios from 'axios';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Swal from './lib/swal';
// import ReactPlayer from 'react-player';

interface IStatus {
	posting: boolean;
	response: {
		success?: boolean;
		error?: any;
	};
}

const App = () => {
	const APIPrefix =
			process.env.NODE_ENV == 'development'
				? 'http://127.0.0.1:8000'
				: 'https://api.a-mole.net',
		[formShown, setFormShown] = React.useState(false),
		[hasAdditionalGuardians, setHasAdditionalGuardians] = React.useState('no'),
		[form] = Form.useForm(),
		[status, setStatus] = React.useState<IStatus>({
			posting: false,
			response: {
				success: false,
			},
		}),
		// schools = [
		// 	'NEW MISSION ACADEMY',
		// 	'AGASSI MEMORIAL SCHOOL',
		// 	'LOBAB ACADEMY',
		// 	'CANDYLAND SCHOOL',
		// 	'VICANDE SCHOOL',
		// 	'SMARTRIBE ONLINE',
		// ],
		onFinish = (payload: Record<string, any>) => {
			setStatus({ ...status, posting: true });
			axios
				.post(`${APIPrefix}/api/smartdubai/registrations`, payload)
				.then(() => {
					setStatus({
						posting: false,
						response: {
							success: true,
						},
					});

					Swal.fire({
						title: <p>Successfully Registered</p>,
						text: 'Your registration was successful. You will be contacted with more details shortly.',
						icon: 'success',
						footer: (
							<a
								href="http://thesmartribe.com"
								target="_blank"
								rel="noreferrer"
							>
								www.thesmartribe.com
							</a>
						),
					});
				})
				.catch(error => {
					if (error.response.status == 422) {
						Swal.fire({
							title: <p>Invalid Details</p>,
							text: 'The details you entered were invalid. Kindly try again or contact us for support on 0205003933 | 0558740741.',
							icon: 'error',
							footer: (
								<a
									href="http://thesmartribe.com"
									target="_blank"
									rel="noreferrer"
								>
									www.thesmartribe.com
								</a>
							),
						});
					} else {
						Swal.fire({
							title: <p>Failed To Register</p>,
							text: 'Your registration failed. Kindly contact us for support on 0205003933 | 0558740741.',
							icon: 'error',
							footer: (
								<a
									href="http://thesmartribe.com"
									target="_blank"
									rel="noreferrer"
								>
									www.thesmartribe.com
								</a>
							),
						});
					}
					setStatus({ response: { error }, posting: false });
				});
		};

	return (
		<div>
			<header>
				<div id="overlay"></div>
				<img src={bgImg} alt="" id="bg-img" />
				<div>
					<h1>
						SmarTribe <span>goes</span> Dubai
					</h1>
				</div>
			</header>
			{!formShown ? (
				<div className="intro slant">
					<div className="logo">
						<img src={logo} alt="SmarTribe Logo" id="logo" />
					</div>
					<div className="container">
						<Row gutter={[12, 12]}>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 24 }}
								md={{ span: 12 }}
								lg={{ span: 12 }}
							>
								<Card style={{ height: '100%', position: 'relative' }}>
									<div className="pattern pattern-dots-md red max-w-20pc overflow-visible"></div>
									<h1 id="intro-label">REGISTER YOUR WARD</h1>
									<p>
										Join smart tribe as we take a trip to the beautiful city of
										Dubai. Expose your kids to the endless possibilities to
										learn, grow and have fun.
									</p>

									<p>
										Join us on this trip to help your kids experience new
										cultures, ideas to change their world as they grow, gain
										knowledge and experiences that are not available in the
										classroom.
									</p>
									<p>Smart Tribe! My Tribe Makes me Smart!</p>
									<Button type="primary" onClick={() => setFormShown(true)}>
										Click To Register
									</Button>
								</Card>
							</Col>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 24 }}
								md={{ span: 12 }}
								lg={{ span: 12 }}
							>
								<Card style={{ height: '100%', border: 0 }}>
									<div className="pattern-dots-md clr1">
										<img id="side-image" src={sideImage} />
									</div>
									{/* <ReactPlayer
										width="100%"
										url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
									/> */}
								</Card>
							</Col>
						</Row>
					</div>
				</div>
			) : (
				<div className="reg-form slant">
					<div className="container">
						<Row gutter={24}>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 24 }}
								md={{ span: 24 - 8 }}
								lg={{ span: 24 - 8 }}
							>
								<Typography.Title level={4}>
									Join Our Field Trip
								</Typography.Title>
								<Typography.Paragraph>
									Fill the form below to register your ward(s) for this amazing
									field trip experience
								</Typography.Paragraph>

								<Form
									form={form}
									layout="vertical"
									onFinish={onFinish}
									initialValues={{
										has_additional_guardians: 'no',
									}}
								>
									<Row gutter={[12, 12]}>
										<Col
											xs={{ span: 24 }}
											sm={{ span: 24 }}
											md={{ span: 12 }}
											lg={{ span: 12 }}
										>
											<Form.Item
												name="guardian_name"
												label="Guardian Name"
												rules={[
													{
														required: true,
														message: 'The Guardian Name field is required',
													},
												]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col
											xs={{ span: 24 }}
											sm={{ span: 24 }}
											md={{ span: 12 }}
											lg={{ span: 12 }}
										>
											<Form.Item
												name="guardian_phone"
												label="Phone Number"
												rules={[
													{
														required: true,
														message: 'The Phone Number field is required',
													},
												]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col
											xs={{ span: 24 }}
											sm={{ span: 24 }}
											md={{ span: 24 }}
											lg={{ span: 24 }}
										>
											<Form.Item
												name="has_additional_guardians"
												label="Will any guardians escort your ward(s) on the trip?"
												rules={[
													{
														required: true,
													},
												]}
											>
												<Radio.Group
													onChange={e =>
														setHasAdditionalGuardians(e.target.value)
													}
												>
													<Radio value="yes">Yes</Radio>
													<Radio value="no">No</Radio>
												</Radio.Group>
											</Form.Item>
										</Col>
										{hasAdditionalGuardians == 'yes' && (
											<Col span={24}>
												<Divider />
												<Typography.Title level={5}>
													Escorting Guardian Details
												</Typography.Title>
												<Divider />
												<Form.List
													name="escorting_guardians"
													initialValue={[{}]}
												>
													{(fields, { add, remove }) => (
														<>
															{fields.map(({ key, name, ...restField }) => (
																<Card
																	size="small"
																	key={key}
																	style={{ marginBottom: 8 }}
																>
																	<div
																		style={{
																			textAlign: 'right',
																			marginBottom: '10px',
																		}}
																	>
																		<Tooltip title="Click to remove guardian">
																			<Button
																				danger
																				icon={
																					<MinusCircleOutlined
																						onClick={() => remove(name)}
																					/>
																				}
																			></Button>
																		</Tooltip>
																	</div>
																	<Row gutter={[12, 12]}>
																		<Col
																			xs={{ span: 24 }}
																			sm={{ span: 24 }}
																			md={{ span: 12 }}
																			lg={{ span: 12 }}
																		>
																			<Form.Item
																				{...restField}
																				name={[name, 'name']}
																				label="Name"
																				rules={[
																					{
																						required: true,
																						message:
																							'The Name field is required',
																					},
																				]}
																			>
																				<Input />
																			</Form.Item>
																		</Col>
																		<Col
																			xs={{ span: 24 }}
																			sm={{ span: 24 }}
																			md={{ span: 12 }}
																			lg={{ span: 12 }}
																		>
																			<Form.Item
																				{...restField}
																				name={[name, 'phone']}
																				label="Phone"
																				rules={[
																					{
																						required: true,
																						message:
																							'The Phone field is required',
																					},
																				]}
																			>
																				<Input />
																			</Form.Item>
																		</Col>
																		<Col
																			xs={{ span: 24 }}
																			sm={{ span: 24 }}
																			md={{ span: 12 }}
																			lg={{ span: 12 }}
																		>
																			<Form.Item
																				{...restField}
																				name={[name, 'gender']}
																				label="Gender"
																				rules={[
																					{
																						required: true,
																						message:
																							'The Gender field is required',
																					},
																				]}
																			>
																				<Radio.Group>
																					{[
																						{
																							label: 'Male',
																							value: 'male',
																						},
																						{
																							label: 'Female',
																							value: 'female',
																						},
																					].map(o => (
																						<Radio
																							key={o.value}
																							value={o.value}
																						>
																							{o.label}
																						</Radio>
																					))}
																				</Radio.Group>
																			</Form.Item>
																		</Col>
																		<Col
																			xs={{ span: 24 }}
																			sm={{ span: 24 }}
																			md={{ span: 12 }}
																			lg={{ span: 12 }}
																		>
																			<Form.Item
																				{...restField}
																				name={[name, 'has_passport']}
																				label="Do you have a passport?"
																				rules={[
																					{
																						required: true,
																						message:
																							'Kindly indicate if this guardian has a passport',
																					},
																				]}
																			>
																				<Radio.Group>
																					{[
																						{
																							label: 'Yes',
																							value: 'yes',
																						},
																						{
																							label: 'No',
																							value: 'no',
																						},
																					].map(o => (
																						<Radio
																							key={o.value}
																							value={o.value}
																						>
																							{o.label}
																						</Radio>
																					))}
																				</Radio.Group>
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}
															<Form.Item>
																<Button
																	type="dashed"
																	onClick={() => add()}
																	icon={<PlusOutlined />}
																>
																	Add another escorting guardian
																</Button>
															</Form.Item>
														</>
													)}
												</Form.List>
											</Col>
										)}
									</Row>
									<Divider />
									<Typography.Title level={5}>Ward(s)</Typography.Title>
									<Divider />
									<Row>
										<Form.List name="wards" initialValue={[{}]}>
											{(fields, { add, remove }) => (
												<>
													{fields.map(({ key, name, ...restField }) => (
														<Card key={key} style={{ marginBottom: 8 }}>
															<div
																style={{
																	textAlign: 'right',
																	marginBottom: '10px',
																}}
															>
																<Tooltip title="Click to remove ward">
																	<Button
																		danger
																		icon={
																			<MinusCircleOutlined
																				onClick={() => remove(name)}
																			/>
																		}
																	></Button>
																</Tooltip>
															</div>
															<Row gutter={[12, 12]}>
																<Col
																	xs={{ span: 24 }}
																	sm={{ span: 24 }}
																	md={{ span: 24 }}
																	lg={{ span: 24 }}
																>
																	<Form.Item
																		{...restField}
																		name={[name, 'name']}
																		label="Ward Name"
																		rules={[
																			{
																				required: true,
																				message:
																					'The Ward Name field is required',
																			},
																		]}
																	>
																		<Input />
																	</Form.Item>
																</Col>
																<Col
																	xs={{ span: 24 }}
																	sm={{ span: 24 }}
																	md={{ span: 12 }}
																	lg={{ span: 12 }}
																>
																	<Form.Item
																		{...restField}
																		name={[name, 'age']}
																		label="Age"
																		rules={[
																			{
																				required: true,
																				message: 'The Age field is required',
																			},
																		]}
																	>
																		<InputNumber style={{ width: '100%' }} />
																	</Form.Item>
																</Col>
																<Col
																	xs={{ span: 24 }}
																	sm={{ span: 24 }}
																	md={{ span: 12 }}
																	lg={{ span: 12 }}
																>
																	<Form.Item
																		{...restField}
																		name={[name, 'gender']}
																		label="Gender"
																		rules={[
																			{
																				required: true,
																				message: 'The Gender field is required',
																			},
																		]}
																	>
																		<Radio.Group>
																			{[
																				{ label: 'Male', value: 'male' },
																				{ label: 'Female', value: 'female' },
																			].map(o => (
																				<Radio key={o.value} value={o.value}>
																					{o.label}
																				</Radio>
																			))}
																		</Radio.Group>
																	</Form.Item>
																</Col>
																<Col
																	xs={{ span: 24 }}
																	sm={{ span: 24 }}
																	md={{ span: 12 }}
																	lg={{ span: 12 }}
																>
																	<Form.Item
																		{...restField}
																		name={[name, 'school']}
																		label="School"
																		rules={[
																			{
																				required: true,
																				message: 'The School field is required',
																			},
																		]}
																	>
																		<Input />
																		{/* <Select
																				showSearch
																				optionFilterProp="children"
																				filterOption={(input, option) =>
																					option!.children
																						.toLowerCase()
																						.indexOf(input.toLowerCase()) >= 0
																				}
																				filterSort={(optionA, optionB) =>
																					optionA.children
																						.toLowerCase()
																						.localeCompare(
																							optionB.children.toLowerCase(),
																						)
																				}
																			>
																				{schools.map(o => (
																					<Select.Option key={o} value={o}>
																						{o}
																					</Select.Option>
																				))}
																			</Select> */}
																	</Form.Item>
																</Col>
																<Col
																	xs={{ span: 24 }}
																	sm={{ span: 24 }}
																	md={{ span: 12 }}
																	lg={{ span: 12 }}
																>
																	<Form.Item
																		{...restField}
																		name={[name, 'grade']}
																		label="Grade"
																		rules={[
																			{
																				required: true,
																				message: 'The Grade field is required',
																			},
																		]}
																	>
																		<Input />
																	</Form.Item>
																</Col>
																<Col
																	xs={{ span: 24 }}
																	sm={{ span: 24 }}
																	md={{ span: 12 }}
																	lg={{ span: 12 }}
																>
																	<Form.Item
																		{...restField}
																		name={[name, 'has_passport']}
																		label="Do you have a passport?"
																		rules={[
																			{
																				required: true,
																				message:
																					'Kindly indicate if this ward has a passport',
																			},
																		]}
																	>
																		<Radio.Group>
																			{[
																				{
																					label: 'Yes',
																					value: 'yes',
																				},
																				{
																					label: 'No',
																					value: 'no',
																				},
																			].map(o => (
																				<Radio key={o.value} value={o.value}>
																					{o.label}
																				</Radio>
																			))}
																		</Radio.Group>
																	</Form.Item>
																</Col>
															</Row>
														</Card>
													))}
													<Form.Item>
														<Button
															type="dashed"
															onClick={() => add()}
															block
															icon={<PlusOutlined />}
														>
															Add another ward
														</Button>
													</Form.Item>
												</>
											)}
										</Form.List>

										<Col span={24} className="d-flex justify-content-center">
											<Form.Item>
												<Button
													type="primary"
													htmlType="submit"
													block
													disabled={status.posting}
													loading={status.posting}
												>
													Submit
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</Form>
							</Col>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 24 }}
								md={{ span: 8 }}
								lg={{ span: 8 }}
							>
								<div className="ads">
									<img src={ad1} alt="" />
									<img src={ad2} alt="" />
								</div>
							</Col>
						</Row>
					</div>
				</div>
			)}

			<footer>
				<div className="footer-hol container">
					<div style={{ textAlign: 'left' }}>
						<p>Copyright 2020 © SmarTribe</p>
					</div>
					<div style={{ textAlign: 'right' }}>
						<p>
							<a href="tel:0205003933">0205003933</a>&nbsp;|&nbsp;
							<a href="tel:0558740741">0558740741</a>
						</p>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default App;
