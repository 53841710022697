import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Registration from './registration';
import View from './view';

const App = () => {
	return (
		<Router>
			<Routes>
				<Route path="/view" element={<View />} />
				<Route path="/" element={<Registration />} />
			</Routes>
		</Router>
	);
};

export default App;
